import slider from "../../resources/js/modules/sliders";
const textMediaSliders = document.querySelectorAll(".text-slider__swiper.swiper");

if (textMediaSliders) {
  slider(textMediaSliders, {
    navigation: {
      nextEl: ".text-slider__btn--next.swiper-button-next",
      prevEl: ".text-slider__btn--prev.swiper-button-prev",
    },
    pagination: {
      el: ".text-slider__pagination.swiper-pagination",
      clickable: true,
    },
  });
}

window.addEventListener('load', () => {

});

window.addEventListener('resize', () => {

});
